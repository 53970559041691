import { createSlice } from "@reduxjs/toolkit";

const initialState = {new: []};

export const notificationsSlice = createSlice({
    name:"notifications",
    initialState,
    reducers: {
        updateNotifications: (state, action) => {
            state.new = action.payload;
            localStorage.setItem("notifications", JSON.stringify(state.new));
            localStorage.setItem("notifications_update", new Date().toISOString());
        }
    }
});

export const {updateNotifications} = notificationsSlice.actions

export default notificationsSlice.reducer