import React from 'react';
import {
    Box,
    IconButton,
    Heading,
    Text,
    Flex,
    Spacer,
    Input,
    StatLabel,
    VStack,
    Stat,
} from '@chakra-ui/react';

import { IoIosSend, IoIosImage } from "react-icons/io";
import { ArrowBackIcon } from '@chakra-ui/icons';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router';

function ChatMessage(props) {
    const timestamp = new Date();
    return (
        <Box bgColor={"gray.100"} 
        borderBottomLeftRadius={12} 
        borderBottomRightRadius={12} 
        borderTopLeftRadius={12}
        borderTopRightRadius={12} 
        px={4}
        py={6}
        w='full'
        >
            <Heading size="xs">{props.sender}</Heading>
            <Stat>
                <StatLabel>{timestamp.toLocaleString()}</StatLabel>
            </Stat>
            <Text>{props.message}</Text>
        </Box>
    )
}

function Chat(props) {
    const chatMessages = [
        {sender:"Dispatcher",
        message:"Call 512-529-7448 when arrived at doc"},
        {sender:"Dispatcher",
        message:"Use door 2"}
    ];

    return (
        <Flex w='full' flexDirection='column'>
            <Flex p={2} overflowY="auto" flexDirection="column" flex={1}>
                <VStack>
                {chatMessages.map(({sender, message}, index) => (
                    <ChatMessage key={index} sender={sender} message={message} />
                ))}
                </VStack>
            </Flex>
            <Flex p={2}>
                <Input placeholder="Enter message" /><IconButton color={'blue.600'} bgColor={'white'} icon={<IoIosImage />} /><IconButton color={'blue.600'} bgColor={'white'} icon={<IoIosSend />} />
            </Flex>
        </Flex>
    );
}

export default function ViewComments(prop) {
    let navigate = useNavigate();
    const { load_id } = useParams();
    const goBack = () => { navigate('/view_load/' + load_id) }

    return (
        <VStack justifyContent="space-between" h='100vh'>
            <Box bgColor={'blue.600'} w='full'>
                <Flex>
                    <Box>
                        <IconButton bgColor={'blue.600'} color={'white'} size="lg" icon={<ArrowBackIcon />} onClick={goBack} />
                    </Box>
                    <Spacer />
                    <Box p={3}>
                        <Heading size="md" color={'gray.50'} fontWeight={600}>Comments</Heading>
                    </Box>
                </Flex>
            </Box>
            <Flex h='full' as="main" flex={1} w='full'>
                <Chat />
            </Flex>
        </VStack>

    );
}