import React from 'react';
import {
  ChakraProvider,
  theme,
} from '@chakra-ui/react';
import { BrowserRouter as Router,
Routes,
Route,
} from "react-router-dom";

import { Navigate, useLocation } from 'react-router-dom';

import LoginPage from './components/LoginPage';
import AssignedLoads from './components/AssignedLoads';
import ActiveLoads from "./components/ActiveLoads";
import PastLoads from './components/PastLoads';
import ViewLoadDetail from './components/ViewLoadDetail';
import Notifications from './components/Notifications';
import ExpenseReports from './components/ExpenseReports';
import ViewComments from './components/ViewComments';
import NewExpenseReport from './components/NewExpenseReport';

function RequireAuth( {children} ) {
  let location = useLocation();
  let token = localStorage.getItem("session_token");
  let auth = false;

  if(token) {
    auth = true;
  }

  if( !auth ) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
}


function App() {
    return (
      <ChakraProvider theme={theme}>
        <Router>
          <Routes>
            <Route exact path="/" element={<LoginPage />} />
            <Route path="/notifications/" element={<Notifications />} />
            <Route path="/loads/active/" element={<ActiveLoads />} />
            <Route path="/loads/past/" element={<PastLoads />} />
            <Route path="/loads/assigned/" element={<RequireAuth><AssignedLoads /></RequireAuth>} />
            <Route path="/expenses/" element={<ExpenseReports />} />
            <Route path="/expenses/new/" element={<NewExpenseReport />} />
            <Route path="/view_load/:load_id" element={<ViewLoadDetail />} />
            <Route path="/view_load/:load_id/comments/" element={<ViewComments />} />
            <Route path="/login/" element={<LoginPage />} />
          </Routes>
        </Router>
      </ChakraProvider>
    ); 
}

export default App;
