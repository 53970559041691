import React, { useState, useRef } from 'react';

import {
    Box,
    Flex,
    Spacer,
    Textarea,
    Button,
    InputLeftElement,
    InputRightAddon,
    Select,
    InputGroup,
    VStack,
    HStack,
    FormControl,
    FormLabel,
    Input,
    IconButton,
    Heading,
    Modal,
    ModalBody,
    useDisclosure,
    ModalFooter,
    ModalContent,
    ModalOverlay,
    Text,
    SimpleGrid,
} from '@chakra-ui/react';

import { ArrowBackIcon } from '@chakra-ui/icons';
import { useNavigate } from 'react-router-dom';
import { BASE_API_URL } from '../Util';

function ExpenseReportPreview(props) {
    return (
        <div>
            <SimpleGrid columns="2">
                <Box fontWeight="semibold">Category</Box>
                <Box>{props.category}</Box>
                <Box fontWeight="semibold">Odometer</Box>
                <Box>{props.odometer}</Box>
                <Box fontWeight="semibold">Amount</Box>
                <Box>${props.amount}</Box>
            </SimpleGrid>
            <Text fontWeight="semibold">Description</Text>
            <Text>{props.description}</Text>
        </div>);
}

function ConfirmationModal(props) {
    const initialState = { previewImageURL: null };
    const [{ previewImageURL }, setState] = useState(initialState);
    const navigate = useNavigate();

    const submitExpenseReport = () => {
        let form_data = new FormData();
        if (props.previewImage) {
            form_data.append("file", props.previewImage)
        }
        const expenseData = {
            amount: props.amount,
            odometer: props.odometer,
            category: props.category,
            description: props.description
        };
        form_data.append('expense_json', JSON.stringify(expenseData));
        const options = {
            method: "POST",
            mode: "cors",
            body: form_data,
            headers: {
                "X-Token": localStorage.getItem("session_token"),
            }
        };
        fetch(BASE_API_URL + "expenses/new/", options).then(response => response.json()).then(data => {
            handleClose("/expenses/");
        });
    }

    const handleClose = (exit_url) => {
        if (previewImageURL) {
            setPreviewImage(null);
        }
        if( exit_url ) {
            navigate(exit_url);
        }
        props.onClose();
    }

    const setPreviewImage = (previewImage) => {
        if (previewImage) {
            setState(prevState => ({ ...prevState, previewImageURL: URL.createObjectURL(props.previewImage) }));
        }
        else if (previewImageURL) {
            URL.revokeObjectURL(previewImageURL);
            setState(initialState);
        }
    }

    if (props.previewImage && previewImageURL === null) {
        setPreviewImage(props.previewImage);
    }
    let previewImage = "";
    if (previewImageURL) {
        previewImage = <img src={previewImageURL} alt="preview" />
    }
    return <Modal isOpen={props.isOpen} onClose={handleClose}>
        <ModalOverlay />
        <ModalContent>
            <ModalBody>
                {previewImage}
                <ExpenseReportPreview category={props.category}
                    odometer={props.odometer}
                    amount={props.amount}
                    description={props.description} />
            </ModalBody>
            <ModalFooter>
                <Button onClick={handleClose} colorScheme="blue">Cancel</Button>&nbsp;
                <Button colorScheme="green" onClick={submitExpenseReport}>Confirm</Button>
            </ModalFooter>
        </ModalContent>
    </Modal>
}

function ValidationDialog(props) {
    return (
        <Modal isOpen={props.isOpen} onClose={props.onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalBody>
                    <Text>{props.text}</Text>
                </ModalBody>
                <ModalFooter><Button colorScheme="blue" onClick={props.onClose}>OK</Button></ModalFooter>
            </ModalContent>
        </Modal>
    )
}

function NewExpenseReportHeader(props) {
    return (
        <Box bgColor={'blue.600'}>
            <Flex>
                <Box>
                    <IconButton bgColor={'blue.600'} color={'white'} size="lg" icon={<ArrowBackIcon />} onClick={props.goBack} />
                </Box>
                <Spacer />
                <Box p={3}>
                    <Heading size="md" color={'gray.50'} fontWeight={600}>Load Details</Heading>
                </Box>
            </Flex>
        </Box>
    );
}

function CreateExpenseReport() {
    const initialState = { category: "Fuel", amount: "", odometer: "", description: "", selectedFile: null, validationMessage: "" }
    const [{ category, amount, odometer, description, validationMessage, selectedFile }, setState] = useState(initialState);

    const { isOpen, onOpen, onClose } = useDisclosure();

    const { isOpen: isConfirmationOpen, onOpen: onConfirmationOpen, onClose: onConfirmationClose } = useDisclosure();

    const submitExpenseReport = () => {
        let valid = true;
        if (amount === "") {
            setState(prevState => ({ ...prevState, validationMessage: "Enter an amount." }))
            onOpen();
            valid = false;
        }
        if (!/[0-9]{1,5}.[0-9]{2}/.test(amount)) {
            setState(prevState => ({ ...prevState, validationMessage: "Enter expense amount in correct format." }))
            onOpen();
            valid = false;
        }
        if (valid) {
            console.log("onConfirmationOpen");
            onConfirmationOpen();
        }

    }

    const fileInput = useRef(null);

    const handleFileUpload = (e) => {
        const fileObj = e.target.files[0];
        const FILE_SIZE_LIMIT = 1024 * 1024 * 2; // 2MB

        if (fileObj) {
            if( fileObj.size > FILE_SIZE_LIMIT ) {
                setState(prevState => ({...prevState, validationMessage: "Image file is too large too upload. Limit is 2MB. Please scale image down before uploading."}));
                onOpen();
            }
            else{
                setState(prevState => ({ ...prevState, selectedFile: fileObj }));
            }   
        }
    }

    return (
        <>
            <Box p={2}>
                <VStack>
                    <FormControl id="expense_category">
                        <FormLabel>Category</FormLabel>
                        <Select value={category} onChange={(e) => {
                            setState(prevState => ({ ...prevState, category: e.target.value }));
                        }}>
                            <option value="Fuel">Fuel</option>
                            <option value="Maintenance">Maintenance</option>
                            <option value="Other">Other</option>
                        </Select>
                    </FormControl>
                    <HStack>
                        <FormControl>
                            <FormLabel>Odometer Reading</FormLabel>
                            <InputGroup>
                                <Input placeholder="0" value={odometer} onChange={e => setState(prevState => ({...prevState, odometer: e.target.value}))} />
                                <InputRightAddon children="miles" />
                            </InputGroup>
                        </FormControl>
                        <FormControl id="amount">
                            <FormLabel>Amount</FormLabel>
                            <InputGroup>
                                <InputLeftElement
                                    pointerEvents="none"
                                    color="gray.300"
                                    fontSize="1.2em"
                                    children="$"
                                />
                                <Input placeholder="0.00" value={amount} onChange={(e) => setState(prevState => ({ ...prevState, amount: e.target.value }))} />
                            </InputGroup>
                        </FormControl>
                    </HStack>
                    <FormControl>
                        <FormLabel>Description</FormLabel>
                        <Textarea placeholder='optional' value={description} onChange={e => setState(prevState => ({ ...prevState, description: e.target.value }))} />
                    </FormControl>
                    <HStack>
                        <div>
                            <input accept='image/*' style={{ display: "none" }} ref={fileInput} type="file" onChange={handleFileUpload} />
                            <Button onClick={() => fileInput.current.click()} colorScheme="blue">Attach Image</Button>
                        </div>

                        <Button colorScheme="green" onClick={submitExpenseReport}>Create</Button>
                    </HStack>
                </VStack>
            </Box>
            <ValidationDialog text={validationMessage} isOpen={isOpen} onClose={onClose} onOpen={onOpen} />
            <ConfirmationModal category={category}
                amount={amount}
                odometer={odometer}
                description={description}
                previewImage={selectedFile}
                isOpen={isConfirmationOpen}
                onClose={onConfirmationClose}
                onOpen={onConfirmationOpen} />
        </>
    )
}

export default function NewExpenseReport(props) {
    const navigate = useNavigate();

    return (<Box>
        <NewExpenseReportHeader goBack={() => navigate("/expenses/")} />
        <CreateExpenseReport />
    </Box>);
}