import React from 'react';
import {
  Spinner,
  VStack,
  Text,
} from '@chakra-ui/react';

export function NoResults(props) {
  return (
    <VStack p={4} justifyContent="space-between" h='100vh'>
        <Text>No results.</Text>
    </VStack>
    ); 
}

export default function LoadingIndicator(props) {
    return (
    <VStack p={4} justifyContent="space-between" h='100vh'>
        <Spinner />
    </VStack>
    );
}