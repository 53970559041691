import React, { useEffect, useState } from "react";
import {
    Drawer,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    DrawerFooter,
    DrawerCloseButton,
    Heading,
    Spinner,
    Input,
    Flex,
    IconButton,
  } from "@chakra-ui/react";
import Comments from "./Comments";
import { IoIosSend, IoIosImage } from "react-icons/io";
import { BASE_API_URL } from "../Util";

export default function CommentsDrawer(props) {
    const initialState = {comments:[], newMessage: "", isSending: false, isLoading: true, loadId: null};

    const [{comments, newMessage, isSending, isLoading, loadId},setState] = useState(initialState);

    const fetchComments = () => {
        const session_token = localStorage.getItem("session_token");
        const url = BASE_API_URL + "loads/load/" + props.loadId + "/comments/";
        console.log("fetchData: " + url);
        fetch(url, {
            mode: "cors", headers: {
                "X-Token": session_token
            }
        })
            .then((resp) => resp.json())
            .then((data) => {
                console.log(data);
                if( data["success"] ) {
                    setState( prevState => ({...prevState,isLoading:false, loadId: props.loadId, comments: data["data"]}));
                }
                else {
                    setState( prevState => ({...prevState,isLoading:false, loadId: props.loadId}));
                }
            });
    }

    const postComment = (message) => {
        const session_token = localStorage.getItem("session_token");

        fetch(BASE_API_URL + "loads/comments/driver/add/", {
            mode: "cors", headers: {
                "Content-Type": "application/json",
                "X-Token": session_token
            },
            method: "POST",
            body: JSON.stringify({ load_id: loadId, comment: message })
        }).then((resp) => resp.json())
            .then((data) => {
                console.log(data);
                setState(prevState => ({ ...prevState, isSending: false, isLoading: true }))
            }
        );
    }


    const onChangeMessage = (action) => {
        setState( prevState => ({...prevState, newMessage: action.target.value}));
    }

    const onSendMessage = () => {
        console.log("Send message: " + newMessage);
        postComment(newMessage);
        setState( prevState => ({...prevState, newMessage: "", isSending: true}));
    }

    let footerBody = "";
    if( !isSending && !isLoading ) {
        footerBody = <Flex p={2}>
            <Input placeholder="Enter message" onChange={onChangeMessage} value={newMessage} /><IconButton color={'blue.600'} bgColor={'white'} icon={<IoIosImage />} /><IconButton onClick={onSendMessage} color={'blue.600'} bgColor={'white'} icon={<IoIosSend />} />
        </Flex>;
    }

    let commentsHeading = <Heading size="md">Comments</Heading>;
    if( isLoading ) {
        commentsHeading = <Heading size="md">Comments&nbsp;<Spinner /></Heading>
    }

    useEffect( () => {
        if( props.isOpen && isLoading ) {
            fetchComments();
        }
    });

    return (
        <Drawer isOpen={props.isOpen}
        placement="right"
        onClose={props.onClose}
        finalFocusRef={props.btnRef}
        colorScheme="blue">
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
            <DrawerHeader>
                {commentsHeading}
            </DrawerHeader>
            <Comments data={comments} />
            <DrawerFooter>
            {footerBody}
            </DrawerFooter>
        </DrawerContent>
      </Drawer>
    );
}