import { createSlice } from "@reduxjs/toolkit";

const initialState = {lastUpdate:null, assignedLoads: [], activeLoads: [], pastLoads: [], selectedLoad: null};

export const loadsSlice = createSlice({
    name:"loads",
    initialState,
    reducers: {
        updateAssignedLoads: (state, action) => {
            state.assignedLoads = action.payload;
            state.lastUpdate = new Date();
            localStorage.setItem("assigned_loads",JSON.stringify(state.assignedLoads));
            localStorage.setItem("assigned_loads_updated", state.lastUpdate.toISOString());
        },
        updateActiveLoads: (state, action) => {
            state.activeLoads = action.payload;
            state.lastUpdate = new Date();
            localStorage.setItem("active_loads",JSON.stringify(state.activeLoads));
            localStorage.setItem("active_loads_updated", state.lastUpdate.toISOString());
        },
        updatePastLoads: (state, action) => {
            state.pastLoads = action.payload;
            state.lastUpdate = new Date();
            localStorage.setItem("past_loads",JSON.stringify(state.pastLoads));
            localStorage.setItem("past_loads_updated", state.lastUpdate.toISOString());
        },
        setSelectedLoad: (state, action) => {
            state.selectedLoad = action.payload;
        },
        setLoadStatus: (state, action) => {
            const {load_id, status, pull_timestamp, drop_timestamp} = action.payload;
            let updated = false;

            for( let eachLoad of state.assignedLoads ) {
                console.log(eachLoad);
                if(eachLoad.load_id === load_id) {
                    eachLoad.status = status;
                    eachLoad.pull_timestamp = pull_timestamp;
                    eachLoad.drop_timestamp = drop_timestamp;
                    updated = true;
                    break;
                }
            }
            if( updated ) {
                localStorage.setItem("assigned_loads", JSON.stringify(state.assignedLoads));
            }


        }
    }
});

export const {updateAssignedLoads, updateActiveLoads, updatePastLoads, setSelectedLoad, setLoadStatus} = loadsSlice.actions

export default loadsSlice.reducer