import React, { useState } from 'react';
import {
    Box,
  Center,
  Heading,
  Input,
  Alert,
  Button,
  AlertIcon,
} from '@chakra-ui/react';
import PasswordInput from './PasswordInput';

import { BASE_API_URL } from '../Util';
import { useNavigate } from 'react-router-dom';

function LoginPage(props) {
    const initialState = {username: "", password: "", loading: false, alertMessage:""};
    const [{username,password, loading, alertMessage},setState] = useState(initialState);
    const navigate = useNavigate();

    let alertMessageDom = "";

    if(alertMessage) {
        alertMessageDom = <Alert status='error'>
            <AlertIcon />
            {alertMessage}
        </Alert>;
    }

    const onChange = (event) => {
        if(event.target.id === "usernameEdit") {
            setState(prevState => ({...prevState, username:event.target.value}));
        }
        else if(event.target.id === "passwordEdit") {
            setState(prevState => ({...prevState, password:event.target.value}));
        }
    }

    const setLoading = (value) => {
        setState( prevState => ({...prevState, loading: value}));
    }

    const handleLogin = () => {
        setLoading(true);
        const formData = {username: username, password: password};
        console.log(formData);
        fetch(BASE_API_URL + "auth/login/", {
            method:"POST",
            mode:"cors",
            headers: {
                'Content-Type': 'application/json'
            },
            body:JSON.stringify(formData)
        }).then(response => response.json())
        .then(data => {
            setLoading(false);
            console.log(data);
            if(data.success) {
                localStorage.setItem("session_token",data.token);
                localStorage.setItem("user_info", JSON.stringify(data.user_info));
                navigate("/loads/assigned/");
            }
            else if(!data.success) {
                setState(prevState => ({...prevState, alertMessage: data.message}));
            }
        });
    }

    return (<Box p={5} maxWidth="500px">
        <Center p={5}><Heading fontSize="3xl">Driver Login</Heading></Center>
        {alertMessageDom}
        <Box p={3}>
            <Input placeholder="Username" id="usernameEdit" onChange={onChange} size="md" />
        </Box>
        <Box p={3}>
            <PasswordInput onChange={onChange} id="passwordEdit" />
        </Box>
        <Box p={3} alignItems="right">
            <Button isLoading={loading} onClick={() => handleLogin()} colorScheme="green">Login</Button>
        </Box>
    </Box>);
}

export default LoginPage;