import React from 'react';
import { useNavigate } from 'react-router-dom';

import {
    Box,
    Button,
    Flex,
    Text,
    Badge,
    VStack,
    Spacer,
} from '@chakra-ui/react';

import { CheckCircleIcon, NotAllowedIcon } from '@chakra-ui/icons';

import HeaderBar from './HeaderBar';
import LoadingIndicator from './LoadingIndicator';
import { format_currency } from '../Util';
import { useGetExpensesQuery } from "../features/api/apiSlice";

function ExpenseItem(props) {
    const timestamp = new Date(props.timestamp).toLocaleString();
    let status_icon = ""
    let status_badge = ""
    if (props.status === "Approved") {
        status_icon = <CheckCircleIcon w="8" h="8" color="green.400" />
        status_badge = <Badge colorScheme="green">Approved</Badge>
    }
    else if (props.status === "Rejected") {
        status_icon = <NotAllowedIcon w="8" h="8" color="red.400" />
        status_badge = <Badge colorScheme={"red"}>Rejected</Badge>
    }

    return (<Box w="100%" p={3} shadow="md" borderWidth="1px" >
        <Flex align="center">
            <Box>
                <Box w="100%">
                    <Flex align="center">
                        <Text fontWeight="bold" size="md" color="green.300" pr={2}>{format_currency(props.amount)}</Text>
                        <Badge>{props.type}</Badge>&nbsp;{status_badge}
                    </Flex>

                    <Flex align="baseline" pb={2}>
                        <Text pr="10">{timestamp}</Text>
                        <Spacer />
                        <Text align="right">{props.odometer} miles</Text>
                    </Flex>
                    <Text>{props.description}</Text>
                </Box>
            </Box>
            <Spacer />
            {status_icon}
        </Flex>
    </Box>)
}

function ListExpenses(props) {
    const items = props.expenseItems.map((each) => <ExpenseItem
        type={each.category}
        timestamp={each.timestamp}
        amount={each.amount}
        odometer={each.odometer}
        description={each.description}
        status={each.status} />);

    return (<VStack>
        {items}
    </VStack>);
}

export default function ExpenseReports(props) {
    const navigate = useNavigate();

    const {
        data,
        isSuccess,
        isFetching,
    } = useGetExpensesQuery();

    if (isFetching) {
        return (
            <Box>
                <HeaderBar headerTitle="Expenses" />
                <LoadingIndicator />
            </Box>
        );
    }

    if (isSuccess && data["success"]) {
        return (
            <Box>
                <HeaderBar headerTitle="Expenses" />
                <Flex p={2}>
                    <Spacer /><Button colorScheme="green" onClick={() => navigate("/expenses/new/")}>New Expense Report</Button>
                </Flex>
                <ListExpenses expenseItems={data.data.map(item => ({
                    timestamp: item.Timestamp,
                    odometer: item.Odometer,
                    status: item.Status,
                    id: item.ExpenseReportId,
                    amount: item.Amount,
                    description: item.Description,
                    category: item.Category,
                    image_path: item.ImagePath,
                }))} />
            </Box>);
    }
}