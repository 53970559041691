import React from 'react';
import {
  Box,
  IconButton,
  useDisclosure,
  Heading,
  Flex,
  Spacer
} from '@chakra-ui/react';
import { HamburgerIcon } from '@chakra-ui/icons';
import HamburgerMenu from './HamburgerMenu';

import { useGetSessionInfoQuery } from '../features/api/apiSlice';


export default function HeaderBar(props) {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const btnRef = React.useRef()

    const {
      data,
      isSuccess,
    } = useGetSessionInfoQuery();

    let unreadNotificationCount = -1;
    let assignedLoadCount = -1;
    let activeLoadCount = -1;
    let pastLoadCount = -1;
    let username = ""
    let firstName = "";
    let lastName = "";

    if( isSuccess && data["success"] ) {
      console.log(data);
      unreadNotificationCount = data["unread_notifications"];
      assignedLoadCount = data["load_counts"]["assigned_count"];
      activeLoadCount = data["load_counts"]["active_count"];
      pastLoadCount = data["load_counts"]["completed_count"];
      username = data["user_info"]["username"];
      firstName = data["user_info"]["first_name"];
      lastName = data["user_info"]["last_name"];
    }

  return (<Box bgColor={'blue.600'}>
    <Flex>
      <Box>
      <IconButton bgColor={'blue.600'} color={'white'} size="lg" icon={<HamburgerIcon onClick={onOpen} />} />
      </Box>
      <Spacer />
      <Box p={3}>
      <Heading size="md" color={'gray.50'} fontWeight={600}>{props.headerTitle}</Heading>
      </Box>
    </Flex>
    <HamburgerMenu unreadNotificationCount={unreadNotificationCount}
      assignedLoadCount={assignedLoadCount}
      activeLoadCount={activeLoadCount}
      pastLoadCount={pastLoadCount}
      username={username}
      firstName={firstName}
      lastName={lastName}
      isOpen={isOpen}
      onClose={onClose}
      btnRef={btnRef} />
  </Box>);
}
