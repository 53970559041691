import React from 'react';
import {
    Box,
    Heading,
    Text,
    Flex,
    StatLabel,
    VStack,
    Stat,
} from '@chakra-ui/react';

function ChatMessage(props) {
    const createdDatetime = new Date(props.timestamp);
    const localeString = createdDatetime.toLocaleTimeString() + ", " + createdDatetime.toLocaleDateString();
    let bgColor = "gray.100";
    if( props.source === "Driver") {
        bgColor = "blue.100"
    }
    return (
        <Box bgColor={bgColor} 
        borderBottomLeftRadius={12} 
        borderBottomRightRadius={12} 
        borderTopLeftRadius={12}
        borderTopRightRadius={12} 
        px={4}
        py={6}
        w='full'
        >
            <Heading size="xs">{props.source} ({props.sender})</Heading>
            <Stat>
                <StatLabel>{localeString}</StatLabel>
            </Stat>
            <Text>{props.message}</Text>
        </Box>
    )
}

export default function Comments(props) {
    let chatMessages = [];
    for (let eachComment of props.data) {
        chatMessages.push({
            sender: eachComment["Sender"],
            message: eachComment["CommentBody"],
            source: eachComment["CommentSource"],
            timestamp: eachComment["CreatedTimestamp"]
        });
    }

    return (
        <VStack justifyContent="space-between" h='100vh'>
        <Flex w='full' flexDirection='column'>
            <Flex p={2} overflowY="auto" flexDirection="column" flex={1}>
                <VStack>
                {chatMessages.map(({sender, message, timestamp, source}, index) => (
                    <ChatMessage key={index} sender={sender} message={message} timestamp={timestamp} source={source} />
                ))}
                </VStack>
            </Flex>
        </Flex>
        </VStack>
    );
}
