import React, { useState } from 'react';
import {
    Box,
    IconButton,
    Heading,
    VStack,
    SimpleGrid,
    Image,
    Text,
    Flex,
    Spacer,
    Divider,
    Spinner,
    useDisclosure,
    Link,
} from '@chakra-ui/react';
import { ArrowBackIcon, ExternalLinkIcon } from '@chakra-ui/icons';
import { useLocation, useParams } from 'react-router';
import StatusMenu from './StatusMenu';
import { useNavigate } from 'react-router';
import { IoIosChatboxes } from 'react-icons/io';
import CommentsDrawer from './CommentsDrawer';
import { useGetLoadDetailsQuery, useUpdateLoadStatusMutation } from "../features/api/apiSlice";

function convertDistance(meters, rate) {
    if (meters === 0) {
        return "0.0 mi";
    }
    let miles = (meters / 1609.344);
    if (rate !== undefined) {
        let rate_per_mile = rate / miles;
        return miles.toFixed(1) + " mi ($" + rate_per_mile.toFixed(2) + "/mi)";
    }
    else {
        return miles.toFixed(1) + " mi";
    }
}

function MapsLink(props) {
    const google_maps_base_url = "https://www.google.com/maps/dir/?api=1&travelmode=driving&destination=";
    const query = `${props.lat},${props.long}`;
    return (<Link href={google_maps_base_url + encodeURIComponent(query)} isExternal>
        Google Maps&nbsp;<ExternalLinkIcon mx='2px' />
    </Link>);
}

function MapBox(props) {
    let mapHeading = "Pickup Location"
    if (props.status === "Active") {
        mapHeading = "Dropoff Location"
    }
    const gmaps_url = props.gmaps_url;
    return (<VStack>
        <Heading size="sm">{mapHeading}</Heading>
        <Image shadow="md" boxSize="240px" src={gmaps_url} />
    </VStack>);
}

function LoadTimestampBox(props) {
    let pickupTimestamp = "None";
    let options = { weekday: 'long', year: 'numeric', month: 'numeric', day: 'numeric', hour: "numeric", minute: "numeric", seconds: "numeric" };
    if (props.pickupTimestamp) {
        let timestamp = new Date(props.pickupTimestamp);
        pickupTimestamp = timestamp.toLocaleString("en-US", options);
    }
    let deliveryTimestamp = "None";
    if (props.deliveryTimestamp) {
        let timestamp = new Date(props.deliveryTimestamp);
        deliveryTimestamp = timestamp.toLocaleString("en-US", options);
    }
    return (<Box>
        <Box><Heading size="xs">Pickup Date/Time</Heading><Text>{pickupTimestamp}</Text></Box>
        <Box><Heading size="xs">Delivery Date/Time</Heading><Text>{deliveryTimestamp}</Text></Box>
    </Box>);
}

function ViewLoadDetailHeaders(props) {
    return (
        <Box bgColor={'blue.600'}>
            <Flex>
                <Box>
                    <IconButton bgColor={'blue.600'} color={'white'} size="lg" icon={<ArrowBackIcon />} onClick={props.goBack} />
                </Box>
                <Spacer />
                <Box p={3}>
                    <Heading size="md" color={'gray.50'} fontWeight={600}>Load Details</Heading>
                </Box>
                <Spacer />
                <IconButton bgColor={'blue.600'} color={'white'} size="lg" icon={<IoIosChatboxes />} onClick={props.onOpen} />
            </Flex>
        </Box>
    );
}

function StatusBox(props) {
    if( props.isUpdating ) {
        return <Spinner />
    }
    if (props.status === "Completed") {
        return <LoadTimestampBox pickupTimestamp={props.pickupTimestamp} deliveryTimestamp={props.deliveryTimestamp} />
    }
    return <MapBox status={props.status} gmaps_url={props.gmapsUrl} />
}

export default function ViewLoadDetail(props) {
    let navigate = useNavigate();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const btnRef = React.useRef()
    const location = useLocation();
    const { load_id } = useParams();
    const [updateStatus, { isUpdating }] = useUpdateLoadStatusMutation()

    let forceReload = true;

    const goBack = () => {

        if (location.state.hasOwnProperty('lastLocation')) {
            navigate(location.state.lastLocation, { state: { forceReload: forceReload } });
        }
        else {
            navigate('/', { state: { forceReload: forceReload } });
        }

    }

    const {
        data,
        isLoading,
        isSuccess,
    } = useGetLoadDetailsQuery(load_id);
    const initialState = { status: "Assigned", pullTimestamp: null, dropTimestamp: null };
    const [{ status, pullTimestamp }, setState] = useState(initialState);
    let loadDetails = null;
    let gmaps_url = null;

    if (isSuccess && data.success) {
        console.log(data);
        loadDetails = {
            loadId: load_id,
            pickupAddress: data.data.pickup.formatted_address,
            pickupLat: data.data.pickup.lat,
            pickupLng: data.data.pickup.lng,
            deliveryAddress: data.data.delivery.formatted_address,
            deliveryLat: data.data.delivery.lat,
            deliveryLng: data.data.delivery.lng,
            weight: data.data.weight,
            description: data.data.description,
            length: data.data.length,
            pickupTimestamp: data.data.pull_timestamp,
            deliveryTimestamp: data.data.drop_timestamp,
            status: data.data.status,
            loadNumber: data.data.load_number,
            distance: data.data.distance,
        }
        if (status !== loadDetails.status) {
            setState(prevState => ({ ...prevState, status: loadDetails.status, pickupTimestamp: loadDetails.pickupTimestamp }));
        }
        else if(!pullTimestamp && loadDetails.pickupTimestamp) {
            console.log(loadDetails.pickupTimestamp);
            setState(prevState => ({...prevState, pullTimestamp: loadDetails.pickupTimestamp }));
        }
        console.log("loadDetails.status: " + loadDetails.status);
        if (loadDetails.status === "Assigned") {
            gmaps_url = `https://maps.googleapis.com/maps/api/staticmap?center=${loadDetails.pickupLat},${loadDetails.pickupLng}&markers=${loadDetails.pickupLat},${loadDetails.pickupLng}&zoom=15&size=240x240&key=AIzaSyDx9UZdUFyhfqr_kkPlhECr2h6MZEGIRCc`;
        }
        else if (loadDetails.status === "Active") {
            gmaps_url = `https://maps.googleapis.com/maps/api/staticmap?center=${loadDetails.deliveryLat},${loadDetails.deliveryLng}&markers=${loadDetails.deliveryLat},${loadDetails.deliveryLng}&zoom=15&size=240x240&key=AIzaSyDx9UZdUFyhfqr_kkPlhECr2h6MZEGIRCc`;
        }
        console.log(gmaps_url);
    }

    if (isLoading) {
        return (
            <Box>
                <CommentsDrawer loadId={load_id} isOpen={isOpen} onClose={onClose} btnRef={btnRef} />
                <ViewLoadDetailHeaders onOpen={onOpen} goBack={goBack} />
                <VStack p={2}>
                    <Flex w='100%'>
                        <Spinner />
                    </Flex>
                </VStack>
            </Box>
        );
    }

    const onUpdateStatus = async (new_status) => {
        let canUpdate = !isUpdating && loadDetails !== null;
        const new_timestamp = new Date();
        console.log("new_status: " + new_status);
        if (canUpdate) {
            if (new_status === "Assigned") {
                try {
                    await updateStatus({ load_id: loadDetails.loadId, pull_timestamp: null, drop_timestamp: null, status: "Assigned" }).unwrap()
                    setState(prevState => ({ ...prevState, status: "New", pullTimestamp: null, dropTimestamp: null }));
                }
                catch (err) {
                    console.error("Exception: " + err);
                }
            }
            else if (new_status === "Active") {
                try {
                    await updateStatus({ load_id: loadDetails.loadId, pull_timestamp: new_timestamp.toISOString(), drop_timestamp: null, status: "Active" }).unwrap()
                    setState(prevState => ({ ...prevState, status: "Active", pullTimestamp: new_timestamp }));
                }
                catch (err) {
                    console.error("Exception: " + err);
                }
            }
            else if (new_status === "Completed") {
                let pull_timestamp_value = null;
                if (pullTimestamp) {
                    pull_timestamp_value = new Date(pullTimestamp).toISOString();
                }
                try {
                    await updateStatus({
                        load_id: loadDetails.loadId, pull_timestamp: pull_timestamp_value,
                        drop_timestamp: new_timestamp.toISOString(), status: "Completed"
                    }).unwrap()
                    setState(prevState => ({ ...prevState, status: "Completed", dropTimestamp: new_timestamp }));
                }
                catch (err) {
                    console.error("Exception: " + err);
                }

            }
        }
        else {
            console.error("Can't update status - already updating.")
        }
    }

    return (
        <Box>
            <CommentsDrawer loadId={load_id} isOpen={isOpen} onClose={onClose} btnRef={btnRef} />
            <ViewLoadDetailHeaders onOpen={onOpen} goBack={goBack} />
            <VStack p={2}>
                <Flex w='100%'>
                    <Box p={1}><Heading size="lg">Load #{loadDetails.loadNumber}</Heading></Box>
                    <Spacer />
                    <Box p={1}><StatusMenu status={status} onUpdateStatus={onUpdateStatus} /></Box>
                </Flex>
                <Divider />
                <Box w='100%'>
                    <Box p={1}><Heading size="sm">Pickup Address</Heading>
                        <Text>{loadDetails.pickupAddress}</Text>
                        <MapsLink lat={loadDetails.pickupLat} long={loadDetails.pickupLng} />
                        </Box>
                    <Box p={1}>
                        <Heading size="sm">Delivery Address</Heading>
                        <Text>{loadDetails.deliveryAddress}</Text>
                        <MapsLink lat={loadDetails.deliveryLat} long={loadDetails.deliveryLng} />
                    </Box>
                    <SimpleGrid columns={3} spacing={5} p={1} w='100%'>
                        <Box><Heading size="sm">Weight</Heading><Text>{loadDetails.weight}</Text></Box>
                        <Box><Heading size="sm">Distance</Heading><Text>{convertDistance(loadDetails.distance)}</Text></Box>
                        <Box><Heading size="sm">Length</Heading><Text>{loadDetails.length} ft</Text></Box>
                    </SimpleGrid>
                    <Box p={1}>
                        <Heading size="sm">Description</Heading>
                        <Text>{loadDetails.description}</Text>
                    </Box>
                </Box>
                <Box w='100%' p={1}>
                    <StatusBox status={status} isUpdating={isUpdating} gmapsUrl={gmaps_url} pickupTimestamp={loadDetails.pickupTimestamp} deliveryTimestamp={loadDetails.deliveryTimestamp} />
                </Box>
            </VStack>
        </Box>);
}