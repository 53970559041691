import { createSlice } from "@reduxjs/toolkit";

const initialState = {username: null, first_name: null, last_name: null, motor_carrier: null, token: null};

export const loadsSlice = createSlice({
    name:"user",
    initialState,
    reducers: {
        setUsername: (state, action) => {
            state.username = action.payload
        },
        setFirstName: (state, action) => {
            state.first_name = action.payload
        },
        setLastName: (state, action) => {
            state.last_name = action.payload
        },
        setMotorCarrier: (state, action) => {
            state.motor_carrier = action.payload
        },
        setToken: (state, action) => {
            state.token = action.payload
        }
    }
});

export const {setUsername, setFirstName, setLastName, setMotorCarrier, setToken} = loadsSlice.actions

export default loadsSlice.reducer