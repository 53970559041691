import { configureStore } from '@reduxjs/toolkit'
import loadsReducer from '../features/loads/loadsSlice';
import userReducer from "../features/user/userSlice";
import notificationsReducer from "../features/notifications/notificationsSlice";
import { apiSlice } from "../features/api/apiSlice";

export const store = configureStore({
  reducer: {
      "loads": loadsReducer,
      "user": userReducer,
      "notifications": notificationsReducer,
      [apiSlice.reducerPath]: apiSlice.reducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(apiSlice.middleware)
})