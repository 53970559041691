import React from 'react';
import {
  Box,
  Alert,
  AlertIcon
} from '@chakra-ui/react';

import { useNavigate } from 'react-router';

import LoadItem from './LoadItem';
import HeaderBar from './HeaderBar';
import LoadingIndicator, { NoResults } from './LoadingIndicator';

import { useGetPastLoadsQuery } from '../features/api/apiSlice';

export default function PastLoads(props) {
    const {
        data,
        isLoading,
        isSuccess,
        isError,
        error
    } = useGetPastLoadsQuery();

    const navigate = useNavigate();

    const viewLoadDetails = (loadId) => {
        navigate('/view_load/' + loadId, {state: {lastLocation: "/loads/past"}});
    }

    let content = [];
    if(isSuccess && data.success) {
        for (let eachLoad of data.data) {
            console.log(eachLoad);
            content.push(<LoadItem key={eachLoad.load_id}
                loadNumber={eachLoad.load_number} 
                pickup={eachLoad.pickup}
                delivery={eachLoad.delivery}
                distance={eachLoad.distance}
                description={eachLoad.description}
                status={eachLoad.status}
                loadId={eachLoad.load_id} onViewLoadDetails={viewLoadDetails}/>);
        }
    }
    else if (isLoading) {
        content.push(<LoadingIndicator />);
    }
    else if (isError) {
        <Alert status="error"><AlertIcon />{error}</Alert>
    }
    
    return(<Box>
        <HeaderBar headerTitle="Past Loads" />
        {content.length > 0 ? content : <NoResults />}
    </Box>);
}