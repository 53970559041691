import React from 'react';
import {
    Box,
    Heading,
    Text,
    Alert,
    AlertIcon,
} from '@chakra-ui/react';

import HeaderBar from './HeaderBar';
import LoadingIndicator, {NoResults} from './LoadingIndicator';

import { useGetNotificationsQuery } from '../features/api/apiSlice';

function NotificationItem(props) {
    const timestamp = new Date(props.timestamp).toLocaleString();
    return (
        <Box p={5} shadow="md" borderWidth="1px">
            <Heading fontSize={"small"} textAlign={"right"}>{timestamp}</Heading>
            <Text>{props.body}</Text>
        </Box>
    );
}

export default function Notifications() {
    const {
        data,
        isLoading,
        isSuccess,
        isError,
        error
    } = useGetNotificationsQuery();

    let content = [];
    if (isSuccess && data.success) {
        content = data.data.map(item => <NotificationItem id={item.NotificationId} 
            timestamp={item.CreatedTimestamp} 
            body={item.Body} />);
    }
    else if (isLoading) {
        content.push(<LoadingIndicator />);
    }
    else if (isError) {
        <Alert status="error"><AlertIcon />{error}</Alert>
    }


    return (
        <Box>
            <HeaderBar headerTitle="Notifications" />
            {content.length > 0 ? content : <NoResults />}
        </Box>
    )
}