import React from 'react';

import {
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerFooter,
  DrawerCloseButton,
  List,
  ListItem,
  Link,
  Text,
  Button,
  Box,
  Stack,
  FormLabel,
  Badge,
} from "@chakra-ui/react";

import { ColorModeSwitcher } from '../ColorModeSwitcher';
import { Link as RouterLink, useNavigate } from 'react-router-dom';

export default function HamburgerMenu(props) {
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem("session_token");
    navigate("/");
  };

  let notificationBadge = "";
  let driver = "Loading...";
  if(props.lastName !== "") {
    driver = `${props.firstName} ${props.lastName} (${props.username})`;
  }
  else {
    driver = props.username;
  }
  notificationBadge = <Badge>{props.unreadNotificationCount}&nbsp;unread</Badge>
  let assignedLoadsBadge = "";
  if( props.assignedLoadCount > 0 ) {
    assignedLoadsBadge = <Badge>{props.assignedLoadCount}</Badge>
  }
  let activeLoadsBadge = "";
  if( props.activeLoadCount > 0 ) {
    activeLoadsBadge = <Badge>{props.activeLoadCount}</Badge>
  }
  let pastLoadsBadge = "";
  if( props.pastLoadCount > 0 ) {
    pastLoadsBadge = <Badge>{props.pastLoadCount}</Badge>
  }


  return (
    <Drawer isOpen={props.isOpen}
      placement="left"
      onClose={props.onClose}
      finalFocusRef={props.btnRef}
      colorScheme="blue">
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>Menu</DrawerHeader>
        <DrawerBody>
          <Stack spacing="24px">
            <Box>
              <FormLabel>Driver</FormLabel>
              <Text>{driver}</Text>
            </Box>
            <Box>
              <List>
                <ListItem><Link as={RouterLink} to="/notifications/">Notifications</Link>&nbsp;{notificationBadge}</ListItem>
                <ListItem><Link as={RouterLink} to="/loads/assigned/">Assigned Loads</Link>&nbsp;{assignedLoadsBadge}</ListItem>
                <ListItem><Link as={RouterLink} to="/loads/active/">Active Loads</Link>&nbsp;{activeLoadsBadge}</ListItem>
                <ListItem><Link as={RouterLink} to="/loads/past/">Past Loads</Link>&nbsp;{pastLoadsBadge}</ListItem>
                <ListItem><Link as={RouterLink} to="/expenses/">Expense Reports</Link></ListItem>
              </List>
            </Box>
          </Stack>
        </DrawerBody>
        <DrawerFooter><ColorModeSwitcher /><Button colorScheme="blue" onClick={handleLogout}>Logout</Button></DrawerFooter>
      </DrawerContent>
    </Drawer>);
}
