import React from 'react';
import { useState } from 'react';
import {
    Menu,
    Button,
    MenuButton,
    MenuList,
    MenuItem,
} from "@chakra-ui/react";

import { ChevronDownIcon } from '@chakra-ui/icons';

export default function StatusMenu( props ) {
    const [status,setStatus] = useState(props.status);

    const updateStatus = (new_status) => {
        props.onUpdateStatus(new_status);
        setStatus(new_status)
    }

    let menuItems = [];
    if( status === "Assigned" ) {
        menuItems.push(<MenuItem onClick={() => updateStatus("Active")}>Log Pickup</MenuItem>);
        menuItems.push(<MenuItem onClick={() => updateStatus("Completed")}>Mark Completed</MenuItem>);
    }
    else if( status === "Active" ) {
        menuItems.push(<MenuItem onClick={() => updateStatus("Completed")}>Log Delivery</MenuItem>);
        menuItems.push(<MenuItem onClick={() => updateStatus("Assigned")}>Reset Load</MenuItem>);
    }
    else if( status === "Completed" ) {
        menuItems.push(<MenuItem onClick={() => updateStatus("Assigned")}>Reset Load</MenuItem>)
    }

    return (
        <Menu>
            <MenuButton as={Button} colorScheme={'red'} rightIcon={<ChevronDownIcon />}>{status}</MenuButton>
            <MenuList>
                {menuItems}
            </MenuList>
        </Menu>
    )
}