import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { BASE_API_URL } from '../../Util';

// Define a service using a base URL and expected endpoints
export const apiSlice = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({ baseUrl: BASE_API_URL , prepareHeaders: (headers, {getState}) => {
      headers.set("X-Token", localStorage.getItem("session_token"));
      return headers;
  }}),
  tagTypes: ['assignedLoads', 'activeLoads', 'pastLoads','loadDetails', 'expenses'],
  endpoints: (builder) => ({
    getSessionInfo: builder.query({
        query: () => 'session/driver/'
    }),
    getAssignedLoads: builder.query({
      query: () => `loads/assigned/`,
      providesTags:['assignedLoads'],
    }),
    getLoadDetails: builder.query({
        query: (load_id) => `loads/load/${load_id}/`,
        providesTags:['loadDetails'],
    }),
    getActiveLoads: builder.query({
        query: () => 'loads/active/',
        providesTags:['activeLoads'],
    }),
    getNotifications: builder.query({
        query: () => 'notifications/',
    }),
    getPastLoads: builder.query({
        query: () => `loads/completed/`,
        providesTags:['completedLoads'],
    }),
    getExpenses: builder.query({
        query: () => `expenses/`,
        providesTags: ['expenses']
    }),
    updateLoadStatus: builder.mutation({
        query: loadStatusDetails => ({
            url: `loads/load/update/`,
            method: "POST",
            body: loadStatusDetails
        }),
        invalidatesTags: ['assignedLoads','activeLoads','pastLoads','loadDetails']
    })
  }),
})

export const { 
    useGetSessionInfoQuery,
    useGetAssignedLoadsQuery, 
    useGetLoadDetailsQuery, 
    useGetActiveLoadsQuery, 
    useGetNotificationsQuery, 
    useGetPastLoadsQuery,
    useGetExpensesQuery,
    useUpdateLoadStatusMutation,
} = apiSlice;