import React from 'react';

import {
  Box,
  Heading,
  Text,
  Badge,
  Flex,
} from '@chakra-ui/react';

import { ArrowForwardIcon } from '@chakra-ui/icons';

function StatusIndicator(props) {
    const value = props.value;
    if(value === "Completed") {
        return <Badge colorScheme={"green"}>Completed</Badge>
    }
    return(
        <Badge>{value}</Badge>
    );   
}

function LoadItem(props) {
    const extractCityState = (addressComponents) => {
        //console.log("addressComponents: " + JSON.stringify(addressComponents));
        let city = null;
        let state = null;
        for(let x in addressComponents) {
            if( addressComponents[x]["types"][0] === "locality" ) {
                city = addressComponents[x]["long_name"];
            }
            if( addressComponents[x]["types"][0] === "administrative_area_level_1" ) {
                state = addressComponents[x]["short_name"];
            }
        }
        if( city && state )
        {
            return city + ", " + state;
        }
        return "";
    }

    const convertMetersToMiles = (meters) => {
        if(meters === 0) {
            return "";
        }
        let miles = meters * 0.000621371
        return miles.toFixed(1) + " mi"
    }

    let pickupCityState = "";
    if( props.pickup ){
        if( props.pickup.address_components !== undefined) {
            pickupCityState = extractCityState(props.pickup.address_components);
        }
    }
    let deliveryCityState = "";
    if( props.delivery ){
        if( props.delivery.address_components !== undefined) {
            deliveryCityState = extractCityState(props.delivery.address_components);
        }
    }    

    return (<Box p={5} shadow="md" borderWidth="1px" onClick={() => props.onViewLoadDetails(props.loadId)} flex={1}>
        <Flex align="center">
            <Heading fontSize="xl" pr="2">Load #{props.loadNumber}</Heading>
            <StatusIndicator value={props.status} />
        </Flex>
        <div>
            <Flex align="center">
                <Text>{pickupCityState}</Text>
                <ArrowForwardIcon />
                <Text>{deliveryCityState}</Text>
            </Flex>
            <Text>{convertMetersToMiles(props.distance)}</Text>
        </div>
        <Text>{props.description}</Text>
    </Box>)
}

export default LoadItem;